// Styles for Menus and Navs
// --------------------------------------------------
.navbar-fixed-top {
    background: url(/sites/all/themes/buencampo_radix/assets/images/mimbre_bg.jpg) 20% 0% rgb(155, 150, 70);
    background-size: 1750px;
}
#user-menu{
    li > a {
        font-size: 13px;
    }
    li#user-img{
        margin-top:-8px;
        margin-bottom:-3px
    }
}
.navbar-brand {
    height: 90px;
}
div#navbar-collapse {
    background: rgba(60,60,60,0.3);
}
@media (min-width: 768px){
    .navbar-brand {
        height: 100px;
    }
}
.navbar-form {
    margin: 13px 0 0;
}
body.admin-menu.adminimal-menu:before{
    display:none;
}
div#navbar-collapse {
    background: rgba(60,60,60,0.4);
}
.navbar-default .navbar-toggle{
	background: #135d0f;
	margin-top:28px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: rgb(21, 169, 17);
}
.nav-pills > li > a {
    padding-left:5px;
    padding-right:5px;
}
@media (min-width: 768px){
	.navbar-brand{
		height: 100px;	
	}
}
@media (min-width: 1045px){
	ul#main-menu{
		margin-top:50px;
	}
}
@media (min-width: 768px)
and (max-width: 1044px){
	ul#menu{
    	margin-top:0;
    }
}
.navbar-nav > li > a,.navbar-nav > li > ul > li > a {
    font-size: 16px;
}
.nav > li.active > a,.navbar-nav > li > ul > li.active > a {
    background-color: #eeeeee;
}
ul.nav > li > ul > li > a {
    text-indent: 1.5em;
}