// Styles for Views
// --------------------------------------------------
.form-item-quantity .form-control {
    display: block;
    width: 50px;
}
.view-taxonomy-term-2,.view-productos,.view-catalog-search-api{
	.views-row article{
		h2 {
		    font-size: 16px;
		    margin: 10px 0 10px;
		    height: 40px;
		}
		min-height:380px;
	}
	.views-row{
		padding:0 10px 10px;
	}
	.views-row:hover {
    	background: rgba(60,255,0,0.2);
	}
	img{
		margin-bottom:10px;
	}
}
.node .field-name-commerce-price {
    font-size: 20px;
    font-weight: 500;
    float: right;
}
.view-blog .views-row,
.view-similarterms .views-row {
    border-bottom: dotted 1px #135d0f;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.view-promociones .views-field.views-field-body {
    position: absolute;
    bottom: 40px;
    right: 60px;
}
form.commerce-add-to-cart input.form-submit {
    float: right;
}
.field.field-name-field-product{
	height: 100px;
}