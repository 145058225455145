// United 3.0.0
// Bootswatch
// -----------------------------------------------------

@import url("//fonts.googleapis.com/css?family=Ubuntu");

// Navbar =====================================================================

// Buttons ====================================================================

// Typography =================================================================

// Tables =====================================================================

// Forms ======================================================================

// Navs =======================================================================

.pagination {

	.active > a,
	.active > a:hover {
		border-color: #ddd;
	}
}

// Indicators =================================================================

// Progress bars ==============================================================

// Containers =================================================================