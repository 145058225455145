img{
  max-width:100%;
  height:auto;
}

body {
    margin-top: 20px !important;
}
body.admin-menu.adminimal-menu {
    margin-top: 40px !important;
}
iframe{
    max-width: 100%;
}
article img {
    margin-bottom: 20px;
}
.node .field-name-commerce-price > div > div:before {
    content: '$';
    float: left;
}

.node .field-name-commerce-price {
    font-size: 20px;
    font-weight: 500;
    float: right;
    border-radius: 10px;
    background: rgba(33, 159, 25,0.3);
    padding: 3px 7px;
}
@media (max-width: 768px){
    .view-promociones{
        margin-left: -15px;
        margin-right: -15px;
    }
}
@media (max-width: 991px){
    ol.inline.commerce-checkout-progress li{
        width: 50%;
    }
}
ol.inline.commerce-checkout-progress li.active{
    color:rgb(21, 169, 17);
}

.icon.fb{
    
}
.icon.pt{
    
}
.icon{
    background-repeat: no-repeat;
    background-position: left center;
    width: 30px;
    height: 40px;
    display: block;
}
.page-user-orders-{
    .field.field-name-commerce-customer-billing.field-type-commerce-customer-profile-reference.field-label-above > div:nth-child(1),
    .field.field-name-commerce-customer-shipping.field-type-commerce-customer-profile-reference.field-label-above > div:nth-child(1)
    {
        font-size:120%;
        font-weight:bold;
    }
    .field.field-name-commerce-customer-shipping.field-type-commerce-customer-profile-reference.field-label-above > div:nth-child(1){
        margin-top:20px;
    }
}
form.commerce-add-to-cart input.form-button-disabled{
    margin-top: 19px;
    clear:both;
}
#messages{
    margin-top: 40px;
}
.panel-pane {
    margin-bottom: 20px;
}
/*Frontpage*/
.front{
    .radix-layouts-footer .pane-block.col-sm-4.clearfix{
        background:#FAFED9;
        padding-bottom: 1em;
        min-height: 340px;
        @media(max-width:767px){
            min-height: auto;
        }
    }

    .pane-1 {
        
        background: rgba(33, 159, 25,0.1);
        padding: 0 20px;
    }
    .fono{
        margin-top:0;
    }
}
/*End of Frontpage*/
.fono{
    margin-top: 10px;
    margin-right: 10px;
}

form#simplenews-block-form-21{
    margin-bottom:1em;
}
.view-content{
    overflow:hidden;
}
/*Ajax add to cart*/
div.add-cart-message-wrapper{
    position: fixed;
    top: 20%;
    right: 10px;
    z-index: 10000;
    background: #ddd;
    padding: 10px 10px 10px;
    a.add-to-cart-close {
        top: 6px;
        position: absolute;
        right: 1px;
        font-size: 25px;
    }
    .product-cost-incl-tax > p,
    .product-quantity > p,
    .product-total-incl-tax > p{
        display:inline-block;   
    }
    .added-product-message{
        font-size:1.3em;
        padding-right: 25px;
        border-bottom:1px solid $gray-light;
    }
    .product-name {
        font-size:1.2em;
    }
    .option-button.checkout{
        margin:5px 0;
    }
    .cost-incl-tax-label,
    .quantity-label,
    .total-label{
        font-weight:bold;
    }
    .new-item-details {
    background: #f1f1f1;
    padding: 8px;
    }
    a.add-to-cart-close {
        height: 24px;
        width: 24px;
        background: url('../images/icon/icon_close.png') no-repeat 50% 50%;
        float: right;
        margin-top: 0px;
        cursor: pointer;
    }
}
div#add-to-cart-overlay {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
    z-index: 10000;
}
/*End of Ajax add to cart*/

.pane-block.buencampo.clearfix {
    margin-bottom: -20px;
}
a.icon{
    height: 40px;
    display: inline-block;
    text-indent: -1000px;
    overflow: hidden;
    width: 40px;
}
a.fb.icon{
    background: url(/sites/all/themes/buencampo_radix/assets/images/icon/facebook.svg) no-repeat;
}
a.instagram.icon{
    background: url(/sites/all/themes/buencampo_radix/assets/images/icon/instagram.svg) no-repeat;
}
a.twitter.icon{
    background: url(/sites/all/themes/buencampo_radix/assets/images/icon/twitter.svg) no-repeat;
}